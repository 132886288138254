import { createSlice } from '@reduxjs/toolkit';
import * as API from './service';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    systemMsg: null,
    systemMsgSeverity: 'error',
    isFetchingQueue: 0,
    bannerMessages: [],
  },
  reducers: {
    updateSystemErrorMsg(state, action) {
      state.systemMsg = action.payload;
      state.systemMsgSeverity = 'error';
    },
    updateSystemMsg(state, action) {
      state.systemMsg = action.payload;
      state.systemMsgSeverity = 'info';
    },
    increaseFetchingQueue(state) {
      state.isFetchingQueue += 1;
    },
    decreaseFetchingQueue(state) {
      state.isFetchingQueue -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(API.getAlertBanners.fulfilled, (state, action) => {
      state.bannerMessages = action.payload;
    });
  },
});

export const {
  updateSystemErrorMsg, updateSystemMsg, increaseFetchingQueue, decreaseFetchingQueue,
} = appSlice.actions;
export default appSlice.reducer;
