import { createTheme } from '@mui/material/styles';
import imageBackground from 'asset/login-background.svg';

export const PRIMARY_DARK = '#002D74';

export const PRIMARY = '#1976D2';

export const PRIMARY_WARNING = '#ff1744';

export const PRIMARY_35 = '#AECFEF';

export const PRIMARY_DEEP_DARK = '#002D74';

export const CHECK_GREEN = '#00BE5F';

export const RUNNING_ORANGE = '#FCBA03';

export const DISABLE_GRAY = '#A2A2A3';

export const WHITE = '#FFFFFF';

export const ERROR_LOGIN = '#88041e';
export const blueTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_35,
      dark: PRIMARY_35,
    },
  },
});

export const loginTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        backgroundImage: `url(${imageBackground})`,
      },
    },
  },
});

export const steps = () => [
  {
    label: 'Scenario Information',
  },
  {
    label: 'Demographics',
  },
  {
    label: 'Policies',
  },
  {
    label: 'Coverage',
    sublabel: ['Hospital Indemnity', 'Critical Illness', 'Accident'],
  },
  {
    label: 'Output',
  },
];

export const genderBenchmark = [
  {
    bmkPercentage: 52.5,
    bmkGender: 'male',
  },
  {
    bmkPercentage: 46.5,
    bmkGender: 'female',
  },
  {
    bmkPercentage: 1,
    bmkGender: 'others',
  },
];

export const relationshipBenchmark = [
  {
    bmkPercentage: 24.8,
    bmkRelationship: 'children',
  },
  {
    bmkPercentage: 61.6,
    bmkRelationship: 'employee',
  },
  {
    bmkPercentage: 13.6,
    bmkRelationship: 'spouse',
  },
];

export const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });

export const intFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });

export const doubleFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });

export const percFormatterNoDecimal = new Intl.NumberFormat('en-US', { style: 'percent' });

export const precFormatter = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 });

export const percFormatterMinZero = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 0 });

export const calculateMaximumDay = (day, type, maxDays) => {
  if (parseInt(day, 10) < 0 || !Number.isInteger(parseInt(day, 10))) { return 0; }
  if (type in maxDays) {
    const n = maxDays[type].length;
    return maxDays[type][Math.min(parseInt(day, 10), n - 1)];
  }
  return 0;
};

export const addlPeriodNames = ['maxDoctorVisit', 'maxTelemed', 'maxChiro', 'maxErVisit', 'maxPrescription', 'maxOutptPhys'];

export const pageSizeOptionList = [10, 25, 50, 100];
export const defaultPageSize = pageSizeOptionList[0];
export const defaultOrder = 'DESC';

export const CARRIER_TOKEN = '{CARRIER}';
export const PDF_MESSAGE = `** THIS IS FOR ${CARRIER_TOKEN} INTERNAL USE ONLY!`;

export const POLICY_MODULE_TYPE = { PERIOD: 'PERIOD', ORIGINAL: 'ORIGINAL', FRACT: 'FRACT' };
export const POLICY_ITEM_TYPE = {
  PERIOD: 'PERIOD', ORIGINAL: 'ORIGINAL', FRACT: 'FRACT', PERCENTAGE: 'PERCENTAGE',
};
export const POLICY_CODE = { HI: 'HI', CI: 'CI', AC: 'AC' };

export const AFLAC = {
  CODE: 'AFLAC',
  PATHNAME: '/aflac',
};
export const BST = {
  CODE: 'BST',
  PATHNAME: '/login',
  PATHNAME_DEFAULT: '/',
};

export const WELLNESS_BENEFIT_MONEY_SPENT = 'Wellness benefit money spent';
export const MONEY_SPENT_DEFAULT = 'Projected Claims';
export const TOOLTIP_CONTENT = 'The projected claims in the modules below are determined by the values included in the General Policy Provisions.';

export const STATUS_CODE = {
  PYTHON_INVALID: 'PYTHON_INVALID',
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS',
};

export const CENSUS_FILE_REMINDER_CONTENT = {
  FILE_INVALID: 'Your current census file is likely in the wrong format. Please upload a valid census file and rerun the scenario.',
  FILE_SUCCESS: 'Your scenario has been successfully processed!',
};

export const DEPENDENCY_OBJ_LIST = [
  {
    code: 'TOTAL',
    claimCode: 'totalCount',
    CICode: '',
  },
  {
    code: 'EMPLOYEE',
    claimCode: 'employeeCount',
    CICode: 'faceValue',
  },
  {
    code: 'SPOUSE',
    claimCode: 'spouseCount',
    CICode: 'adultDp',
  },
  {
    code: 'CHILDREN',
    claimCode: 'childrenCount',
    CICode: 'minorDp',
  },
];

export const getBestBenefitPrice = (price) => Math.round(
  Math.ceil((price * 1.25) / 25) * 25,
);

export const DOMAIN_PREFIX_REGEX = /^https?:\/\/[^/]+/;

export const specificBenefitChecked = {
  BST: {
    HI: 'wellnessVisitChecked',
    CI: 'wellnessChecked',
    AC: 'wellnessChecked',
  },
  AFLAC: {
    HI: 'healthScreeningChecked',
    CI: 'healthScreenChecked',
    AC: 'healthScreeningChecked',
  },
  ALLSTATE: {
    HI: 'wellnessVisitChecked',
    CI: 'healthScreenChecked',
    AC: 'healthScreeningChecked',
  },
};

export const policyDisplayNames = {
  HI: 'Hospital Indemnity',
  CI: 'Critical Illness',
  AC: 'Accident',
};

export const fixedProductPriority = {
  AC: 1,
  HI: 2,
  CI: 3,
};

export const avgHospitalBenefits = {
  BST: {
    benefit1: 'nonIcuAdmission',
    benefit1Checked: 'nonIcuAdmissionChecked',
    benefit2: 'nonIcuPerDiem',
    benefit2Checked: 'nonIcuPerDiemChecked',
  },
  AFLAC: {
    benefit1: 'admission',
    benefit1Checked: 'admissionChecked',
    benefit2: 'confinement',
    benefit2Checked: 'confinementChecked',
  },
  ALLSTATE: {
    benefit1: 'firstDayHospitalConfinement',
    benefit1Checked: 'firstDayHospitalConfinementChecked',
    benefit2: 'dailyHospitalConfinement',
    benefit2Checked: 'dailyHospitalConfinementChecked',
  },
};

export const NUMBERS_ROUNDED_TEXT = 'Numbers are rounded to the nearest whole number according to the standard rounding rules. Ex. 6.4 rounded to 6 and 6.6 rounded to 7';

export const premiumTypes = [
  { type: 'Employee Only', name: 'EE' },
  { type: 'Employee + Spouse', name: 'ES' },
  { type: 'Employee + Child(ren)', name: 'EC' },
  { type: 'Employee + Family', name: 'EF' },
];

export const frequencyOptions = [
  {
    code: 'SELECT',
    text: 'Select',
  },
  {
    code: 'MONTHLY',
    text: 'Monthly',
  },
  {
    code: 'ANNUAL',
    text: 'Annually',
  },
  {
    code: 'WEEKLY',
    text: 'Weekly',
  },
  {
    code: 'BIWEEKLY',
    text: 'Biweekly',
  },
];

export const smokerOptions = [
  {
    code: 'SELECT',
    text: 'Select',
  },
  {
    code: 'UNISMOKER',
    text: 'Unismoker',
  },
  {
    code: 'SMOKER',
    text: 'Nonsmoker/Smoker',
  },
];

export const premiumAges = ['25', '35', '45', '55', '65'];

export const regionOptions = ['All', 'South', 'Midwest', 'Northeast', 'West', 'N/A'];

export const comparisonOperators = ['=', '>=', '<='];

export const defaultFilters = {
  name: '',
  employerName: '',
  region: ['All'],
  numOfMembers: { value: '', operator: '=' },
  userName: '',
  createdAt: { value: '', operator: '=' },
};
