import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import RoutePath from 'app/router/RoutePath';
import { STATUS_CODE } from 'common/Constants';
import store from 'app/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container } from '@mui/system';
import VerticalStepper from './common/verticalStepper';
import ScenarioInformation from './scenarioInformation';
import Demograhpic from './demographic';
import Policy from './policy';
import Coverage from './coverage';
import Output from './output';
import {
  resetScenario, updateScenarioSection,
  updateHospitalIndemnityPayout,
  updateCriticalIllnessPayout, updateAccidentPayout,
  updateClaimWeight, updateCategoryCode,
} from './state/reducer';
import {
  getClaimById, getCriticalIllnessById, getHospitalIndemnityById,
  getAccidentById, getPolicyAdjustmentsById, getEnrollCondConsts,
  getDemographicById, getById, getPolicyInfo, getCustomTextByScenarioId, getParticipationPerc,
  getCIPremiums, getHIACPremiums,
} from './state/service';

export default function Navigate() {
  const navigate = useNavigate();

  let nameDefaultValue = '';
  const scenario = useSelector((state) => state.scenario.scenario);
  if (scenario?.name) {
    nameDefaultValue = scenario.name.charAt(0).toUpperCase() + scenario.name.slice(1);
  }
  const processStatus = useSelector((state) => state.scenario.processStatus);
  const currPage = useSelector((state) => state.scenario.scenarioSection);
  const claimFetchFlag = useSelector((state) => state.scenario.claimFetchFlag);
  const claimHoldFlag = useSelector((state) => state.scenario.claimHoldFlag);
  const participationPercentages = useSelector((state) => state.scenario.participationPercentages);
  const hospitalIndemnityPayoutFlag = useSelector(
    (state) => state.scenario.hospitalIndemnityPayoutFlag,
  );
  const criticalIllnessPayoutFlag = useSelector(
    (state) => state.scenario.criticalIllnessPayoutFlag,
  );
  const accidentPayoutFlag = useSelector(
    (state) => state.scenario.accidentPayoutFlag,
  );

  const claimWeightUpdateFlag = useSelector(
    (state) => state.scenario.claimWeightUpdateFlag,
  );

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams && searchParams.get('id')) {
      store.dispatch(getById(searchParams.get('id')));
    } else {
      store.dispatch(resetScenario());
    }
  }, [searchParams]);

  const handleCancelCreation = () => {
    store.dispatch(resetScenario());
    const link = RoutePath.app.scenario.fullpath;
    navigate(link);
  };

  const goToPreviousPage = () => {
    store.dispatch(updateScenarioSection(Math.max(currPage - 1, 0)));
  };

  const goToNextPage = () => {
    store.dispatch(updateScenarioSection(Math.min(currPage + 1, 4)));
  };

  const bottomBarFunctions = { handleCancelCreation, goToNextPage, goToPreviousPage };

  useEffect(() => {
    if (searchParams && searchParams.get('id') && processStatus === STATUS_CODE.SUCCESS) {
      store.dispatch(getClaimById(searchParams.get('id')));
    } else {
      // store.dispatch(resetDemographic());
    }
  }, [claimFetchFlag, searchParams, processStatus]);

  useEffect(() => {
    store.dispatch(updateHospitalIndemnityPayout());
  }, [hospitalIndemnityPayoutFlag, claimHoldFlag, participationPercentages]);

  useEffect(() => {
    store.dispatch(updateCriticalIllnessPayout());
  }, [criticalIllnessPayoutFlag, claimHoldFlag, participationPercentages]);

  useEffect(() => {
    store.dispatch(updateAccidentPayout());
  }, [accidentPayoutFlag, claimHoldFlag, participationPercentages]);

  useEffect(() => {
    store.dispatch(updateClaimWeight());
  }, [claimWeightUpdateFlag]);

  useEffect(() => {
    store.dispatch(getPolicyInfo()).unwrap().then(() => {
      if (searchParams && searchParams.get('id') && processStatus === STATUS_CODE.SUCCESS) {
        const scenarioId = searchParams.get('id');
        store.dispatch(getDemographicById(scenarioId));
        store.dispatch(getHospitalIndemnityById(scenarioId));
        store.dispatch(getCriticalIllnessById(scenarioId));
        store.dispatch(getAccidentById(scenarioId));
        store.dispatch(getPolicyAdjustmentsById(scenarioId));
        store.dispatch(getEnrollCondConsts());
        store.dispatch(getCustomTextByScenarioId(scenarioId));
        store.dispatch(getParticipationPerc({ scenarioId, policyCode: 'HI' }));
        store.dispatch(getParticipationPerc({ scenarioId, policyCode: 'AC' }));
        store.dispatch(getCIPremiums(scenarioId));
        store.dispatch(getHIACPremiums(scenarioId));
      }
      store.dispatch(updateCategoryCode());
    });
  }, [searchParams, processStatus]);

  useEffect(() => {
    const scenarioId = searchParams.get('id');
    if (scenarioId) {
      store.dispatch(getById(scenarioId));
    }
  }, [searchParams]);

  return (
    <main>
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" mystyle="pageHeader">
            {searchParams.get('id') ? `${(nameDefaultValue)} Scenario` : 'Create a Scenario'}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={0.5} />
          <Grid container item xs={2} direction="column" sx={{ minWidth: '240px' }}>
            <VerticalStepper />
          </Grid>

          <Grid container item xs={9}>
            <Container sx={{ minWidth: '1200px' }}>
              {currPage === 0 && <ScenarioInformation bottomBarFunctions={bottomBarFunctions} />}
              {currPage === 1 && <Demograhpic bottomBarFunctions={bottomBarFunctions} />}
              {currPage === 2 && <Policy bottomBarFunctions={bottomBarFunctions} />}
              {currPage === 3 && <Coverage bottomBarFunctions={bottomBarFunctions} />}
              {currPage === 4 && <Output bottomBarFunctions={bottomBarFunctions} />}
            </Container>
          </Grid>
        </Grid>

      </Grid>
      <Box component="footer" sx={{ fontSize: '0.8rem', marginBottom: '45px' }}>
        MultiPlan Inc. All Rights Reserved.
      </Box>
    </main>
  );
}
