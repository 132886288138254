import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, InputAdornment } from '@mui/material';
import debounce from 'lodash.debounce';
import { THIS_FIELD_IS_REQUIRED } from 'common/ErrorConstants';
import { StyledTableCell } from './styledTableComponent';

export default function PolicyTableInputCell(props) {
  const {
    name, onChange, adornment, min, max, isBenefit2, isDisabled, defaultValue, isPremiumAmountField,
  } = props;

  const inputAdornment = {};
  if (adornment === 'percent') {
    inputAdornment.endAdornment = <InputAdornment position="end">%</InputAdornment>;
  } else if (adornment === 'dollar') {
    inputAdornment.startAdornment = <InputAdornment position="start">$</InputAdornment>;
  }

  const {
    control, getValues, setValue, trigger,
  } = useFormContext();

  const calcTotalEnrollment = () => {
    let totalEnrollment = getValues('expectedEnrollment');
    for (let i = 2; i <= 5; i += 1) {
      totalEnrollment += getValues(`expectedEnrollmentOption${i}`) ?? 0;
    }
    return totalEnrollment;
  };

  const onlyFirstFVSelected = (name === 'expectedEnrollment' && !getValues('faceValueOption2Checked'));

  return (
    <StyledTableCell align="left">
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? getValues(name)}
        rules={{
          required: isBenefit2 ? false : THIS_FIELD_IS_REQUIRED,
          validate: (value) => {
            if (name === 'expectedEnrollmentTotal') {
              const total = parseInt(value, 10);
              return total === 100 || 'Must add up to 100%';
            }
            return null;
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            id={`input_${name}`}
            data-testid={`${name}`}
            label=""
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            {...field}
            maxWidth="xs"
            InputProps={inputAdornment}
            error={error}
            helperText={error ? error.message : null}
            onWheel={(e) => e.target.blur()}
            onBlur={(event) => {
              if (isPremiumAmountField) {
                let val = event.target.value;
                if (val < 0) {
                  val = Math.abs(Number(val)).toFixed(2);
                } else if (val) {
                  val = Number(val).toFixed(2);
                }
                setValue(name, val, { shouldDirty: true });
              }
            }}
            onChange={(event) => {
              let input = event.target.value;
              if (input && !Number.isNaN(input) && input.includes('.') && !isPremiumAmountField) {
                input = Math.round(parseFloat(input));
              }
              if (!input && input !== 0 && input !== '0') {
                if (name !== 'participationPerc1') {
                  input = null;
                }
              } else if (event.target.value < 0 || (min && event.target.value < min)) {
                if (!isPremiumAmountField) {
                  input = 0;
                }
              } else if (max && event.target.value > max) {
                input = max;
              } else if (adornment === 'percent' && onlyFirstFVSelected) {
                input = 100;
              } else if (adornment === 'percent') {
                input = Math.min(100, input);
              }
              setValue(name, input, { shouldDirty: true });
              if (name === 'participationPerc1') {
                setValue('participationPerc2', 100 - input);
              }
              if (name.startsWith('expectedEnrollment')) {
                setValue('expectedEnrollmentTotal', calcTotalEnrollment());
                trigger('expectedEnrollmentTotal');
              }
              setTimeout(
                debounce(() => {
                  trigger(name);
                  onChange();
                }, 100),
              );
            }}
            disabled={isDisabled}
          />
        )}
      />
    </StyledTableCell>
  );
}
