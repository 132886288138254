import React from 'react';
import {
  Grid, TextField, Typography, Checkbox, ListItemText, FormControl, Select, MenuItem,
} from '@mui/material';
import { regionOptions, comparisonOperators } from 'common/Constants';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'styles.css';

export default function FilterableHeader({
  columnLabel, filterValue, onFilterChange, filterable = true, isDropdown = false,
  isHeadcount = false, isCreated = false, error = '', filters, onDateChange,
}) {
  const findFilterChangeValue = (e) => {
    const prevValue = isHeadcount ? filters.numOfMembers : filters.createdAt;
    return {
      ...prevValue,
      operator: e.target.value,
    };
  };

  if (isHeadcount || isCreated) {
    return (
      <Grid container direction="column" alignItems="flex-start" spacing={2}>
        <Grid item onClick={(e) => e.stopPropagation()}>
          <FormControl size="small" sx={{ width: '60px' }} className="extra-small-text-field">
            <Select
              value={isHeadcount ? filters.numOfMembers.operator : filters.createdAt.operator}
              onChange={(e) => onFilterChange(findFilterChangeValue(e))}
              data-testid={`${columnLabel}-dropdown`}
            >
              {comparisonOperators.map((operator) => (
                <MenuItem key={operator} value={operator} className="custom-menu-item">
                  {operator}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item onClick={(e) => e.stopPropagation()}>
          {isHeadcount ? (
            <TextField
              size="small"
              variant="outlined"
              label="Filter"
              className="extra-small-text-field"
              value={filters.numOfMembers.value}
              onChange={(e) => onFilterChange({
                ...filters.numOfMembers,
                value: e.target.value,
              })}
              error={!!error}
              helperText={error}
              data-testid={`${columnLabel}-text`}
            />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    inputProps={{ readOnly: true }}
                  />
                )}
                label="Filter"
                className="custom-text-field"
                onChange={onDateChange}
                value={filters.createdAt.value ? dayjs(filters.createdAt.value) : null}
                slotProps={{
                  actionBar: {
                    actions: ['clear', 'accept'],
                  },
                  textField: {
                    onKeyDown: (e) => {
                      e.preventDefault();
                    },
                  },
                }}
              />
            </LocalizationProvider>
          )}
        </Grid>
        <Grid item>
          <Typography variant="h7">{columnLabel}</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container direction="column" alignItems="flex-start" spacing={filterable ? 2 : 6.2}>
      <Grid item height="52px">{' '}</Grid>
      {filterable ? (
        <Grid item onClick={(e) => e.stopPropagation()}>
          {isDropdown ? (
            <FormControl size="small" sx={{ width: '95px' }} className="extra-small-text-field">
              <Select
                multiple
                value={filterValue}
                onChange={onFilterChange}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: 200 },
                  },
                }}
                data-testid={`${columnLabel}-dropdown`}
              >
                {regionOptions.map((region) => (
                  <MenuItem key={region} value={region} className="custom-menu-item" data-testid={`${region}-menuitem`}>
                    <Checkbox checked={filterValue.includes(region)} />
                    <ListItemText primary={region} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              size="small"
              variant="outlined"
              label="Filter"
              className="extra-small-text-field"
              value={filterValue}
              onChange={onFilterChange}
              helperText={error}
              error={!!error}
              data-testid={`${columnLabel}-text`}
            />
          )}
        </Grid>
      ) : (
        <Grid item>
          {' '}
        </Grid>
      )}
      <Grid item>
        <Typography variant="h7">{columnLabel}</Typography>
      </Grid>
    </Grid>
  );
}
