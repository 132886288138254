import React, { useEffect, useState } from 'react';
import {
  Table, Accordion, AccordionDetails, Grid, Box,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  formatter, calculateMaximumDay, intFormatter, POLICY_ITEM_TYPE, POLICY_MODULE_TYPE,
} from 'common/Constants';
import { AFLAC_CARRIER_CODE } from 'common/AppConstant';
import { StyledTableCell, StyledTableRow } from '../common/styledTableComponent';
import PolicyTableHead from '../common/policyTableHead';
import PolicyTableCheckboxCell from '../common/policyTableCheckboxCell';
import PolicyTableInputCell from '../common/policyTableInputCell';
import PolicyAccordionSummary from '../common/policyAccordionSummary';
import { updateACCategoryHighlight } from '../state/reducer';

export default function Accident({ setAccidentFormValues, isProductIncludedChecked }) {
  const {
    getValues, setValue, watch, formState: { errors },
  } = useFormContext();

  const carrierCode = useSelector((state) => state.auth?.carrierCode);

  const claim = useSelector((state) => state.scenario.claim);

  const claimWeight = useSelector(
    (state) => state.scenario.claimWeight,
  );
  const accidentCategoryCode = useSelector(
    (state) => state.scenario.ACCategoryCode,
  );

  const ACModuleList = useSelector((state) => state.scenario.ACModuleList);

  const maxDays = useSelector((state) => state.scenario.maxDays);

  const blankFlagObject = {};
  ACModuleList.forEach((item) => {
    blankFlagObject[item.code] = null;
  });

  const watcher = [];
  ACModuleList.forEach((moduleItem) => {
    watcher.push([]);
    accidentCategoryCode[moduleItem.code].forEach((item) => {
      watcher[watcher.length - 1].push(watch(`${item.name}Checked`));
    });
  });

  const [checkedChangingFlag, setCheckedChangingFlag] = useState(false);
  const [payout, setPayout] = useState({});
  const [totalClaims, setTotalClaims] = useState({});
  const [totalSpent, setTotalSpent] = useState({});
  const [expanded, setExpanded] = useState(blankFlagObject);
  const [headerChecked, setHeaderChecked] = useState(blankFlagObject);
  const [headerIndeterminate, setHeaderIndeterminate] = useState(blankFlagObject);
  const ACPayoutDetail = useSelector((state) => state.scenario.ACPayoutDetail);
  const dispatch = useDispatch();

  const setHeaderCheckboxStatus = () => {
    const output = {};
    watcher.forEach((flag, index) => {
      const n = flag.length;
      const m = flag.reduce((acc, val) => acc + val, 0);
      if (m === 0) {
        setHeaderChecked((prev) => ({ ...prev, [ACModuleList[index].code]: false }));
        setHeaderIndeterminate((prev) => ({ ...prev, [ACModuleList[index].code]: false }));
        output[ACModuleList[index].code] = true;
      } else if (m === n) {
        setHeaderChecked((prev) => ({ ...prev, [ACModuleList[index].code]: true }));
        setHeaderIndeterminate((prev) => ({ ...prev, [ACModuleList[index].code]: false }));
        output[ACModuleList[index].code] = false;
      } else {
        setHeaderChecked((prev) => ({ ...prev, [ACModuleList[index].code]: false }));
        setHeaderIndeterminate((prev) => ({ ...prev, [ACModuleList[index].code]: true }));
        output[ACModuleList[index].code] = true;
      }
    });
    return output;
  };

  const updateAllPayout = () => {
    const tempPayout = {};

    Object.entries(accidentCategoryCode).forEach((list) => {
      list[1].forEach((item) => {
        if (item.code in claim) {
          const benefit1 = getValues(item.name);
          const benefit2 = getValues(`${item.name}2`);
          const count = Math.round(claim[item.code].totalCount * claimWeight.AC);
          const participationPerc1 = getValues('participationPerc1') / 100;
          const participationPerc2 = getValues('participationPerc2') / 100;
          const initialPayout = (benefit1 * (participationPerc1 * count))
                            + (benefit2 * (participationPerc2 * count));

          if (item.type === POLICY_ITEM_TYPE.FRACT) {
            const closed1 = getValues(`${item.name}Closed`);
            const open1 = getValues(`${item.name}Open`);
            const closed2 = getValues(`${item.name}Closed2`);
            const open2 = getValues(`${item.name}Open2`);
            const { fractureInjuriesWeight } = item;
            const payout1 = Math.round((closed1 * fractureInjuriesWeight + open1
              * (1 - fractureInjuriesWeight)) * (participationPerc1 * count));
            const payout2 = Math.round((closed2 * fractureInjuriesWeight + open2
                * (1 - fractureInjuriesWeight)) * (participationPerc2 * count));
            tempPayout[item.name] = (payout1 + payout2) || 0;
          } else if (item.type === POLICY_ITEM_TYPE.PERIOD) {
            tempPayout[item.name] = Math.round(initialPayout
                        * calculateMaximumDay(
                          getValues(item.periodName),
                          item.periodCode,
                          maxDays,
                        )) || 0;
          } else {
            tempPayout[item.name] = initialPayout || 0;
          }
        }
      });
    });

    Object.entries(accidentCategoryCode).forEach((list) => {
      list[1].forEach((item) => {
        if (item.code in claim) {
          setPayout((prev) => ({
            ...prev,
            [item.name]: tempPayout[item.name],
          }));
        }
      });
    });

    ACModuleList.forEach((moduleItem) => {
      setTotalSpent((prev) => ({
        ...prev,
        [moduleItem.code]: accidentCategoryCode[moduleItem.code].reduce((
          acc,
          item,
        ) => (item.name in tempPayout
          ? acc + tempPayout[item.name] * Number(getValues(`${item.name}Checked`))
          : acc), 0),
      }));
    });
  };

  const onClickHeaderCheckbox = (key) => {
    const flag = setHeaderCheckboxStatus();
    accidentCategoryCode[key].forEach((item) => {
      setValue(`${item.name}Checked`, flag[key]);
    });
    setCheckedChangingFlag(!checkedChangingFlag);
    updateAllPayout();
  };

  const onCheckedFieldChange = () => {
    setCheckedChangingFlag(!checkedChangingFlag);
  };

  useEffect(() => {
    updateAllPayout();

    ACModuleList.forEach((moduleItem) => {
      setTotalClaims((prev) => ({
        ...prev,
        [moduleItem.code]: accidentCategoryCode[moduleItem.code].reduce((acc, item) => {
          if (item.code in claim) {
            if ((item.type === POLICY_ITEM_TYPE.PERIOD || item.name === 'facilityFees') && item.relatedName !== null && getValues(`${item.relatedName}Checked`) !== undefined) {
              return acc + Math.round(claim[item.code].totalCount * claimWeight.AC) * Math.min(Number(getValues(`${item.name}Checked`))
                + Number(getValues(`${item.relatedName}Checked`)), 1)
                - Math.round(claim[item.code].totalCount * claimWeight.AC) * Number(getValues(`${item.relatedName}Checked`));
            }
            return acc + Math.round(claim[item.code].totalCount * claimWeight.AC) * Number(getValues(`${item.name}Checked`));
          }
          return acc;
        }, 0),
      }));
    });
  }, [claim, claimWeight, checkedChangingFlag]);

  useEffect(() => {
    setHeaderCheckboxStatus();
    updateAllPayout();
  }, []);

  useEffect(() => {
    setHeaderCheckboxStatus();
  }, [checkedChangingFlag]);

  useEffect(() => {
    const allItems = Object.values(accidentCategoryCode).flat();
    const itemNames = allItems.flatMap((item) => [item.name, `${item.name}Checked`]);
    const filteredValues = Object.keys(getValues()).reduce((acc, key) => {
      if (itemNames.includes(key)) {
        return { ...acc, [key]: getValues(key) };
      }
      return acc;
    }, {});
    setAccidentFormValues(filteredValues);
    const subscription = watch((values) => {
      const filteredValuesLatest = Object.keys(values).reduce((acc, key) => {
        if (itemNames.includes(key)) {
          return { ...acc, [key]: getValues(key) };
        }
        return acc;
      }, {});
      setAccidentFormValues(filteredValuesLatest);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const invalidCategories = new Set();

    Object.keys(errors).forEach((error) => {
      let categoryName;
      if (error === 'participationPerc1') {
        categoryName = ACModuleList[0]?.code;
      } else if (error.includes('Period')) {
        categoryName = ACPayoutDetail.find((benefit) => benefit.periodName === error)
          ?.module.code;
      } else {
        let benefitName = error;
        if (error.slice(-6) === 'Closed') {
          benefitName = error.slice(0, -6);
        } else if (error.slice(-4) === 'Open') {
          benefitName = error.slice(0, -4);
        }
        categoryName = ACPayoutDetail.find((benefit) => benefit.name === benefitName)
          ?.module.code;
      }
      invalidCategories.add(categoryName);
    });
    dispatch(updateACCategoryHighlight({ invalidCategories: [...invalidCategories] }));
  }, [watch()]);

  return (
    <Grid container spacing={2}>
      {ACModuleList.map((moduleItem, i) => (
        accidentCategoryCode[moduleItem.code].length > 0
        // temporary hide these 2 sections due to mis-calculations for Aflac
        && ((carrierCode !== AFLAC_CARRIER_CODE) || (moduleItem.code !== 'lifeChangingEvents' && moduleItem.code !== 'commonInjuriesDislocations'))
        && (
          <Grid item xs={12}>
            <Accordion expanded={expanded[moduleItem.code]}>
              <PolicyAccordionSummary
                id={`AC-${moduleItem.code}`}
                title={moduleItem.displayName}
                onChange={onClickHeaderCheckbox}
                setExpanded={setExpanded}
                moduleCode={moduleItem.code}
                checked={headerChecked}
                indeterminate={headerIndeterminate}
                totalClaims={totalClaims[moduleItem.code]}
                totalSpent={totalSpent[moduleItem.code]}
                isInvalid={moduleItem.isInvalid}
                isDisabled={!isProductIncludedChecked}
              />
              <AccordionDetails sx={{ padding: 0 }}>
                <TableContainer component={Paper} margin={0}>
                  <Table aria-label="main table">
                    <PolicyTableHead type={moduleItem.tableType} />
                    <TableBody>
                      <Box />
                      <StyledTableCell align="left" component="th" scope="row">
                        Participation %
                      </StyledTableCell>
                      <PolicyTableInputCell
                        name="participationPerc1"
                        onChange={updateAllPayout}
                        adornment="percent"
                        isDisabled={i !== 0 || !isProductIncludedChecked}
                      />
                      {moduleItem.tableType === POLICY_MODULE_TYPE.FRACT && (<Box />)}
                      <PolicyTableInputCell
                        name="participationPerc2"
                        onChange={() => {}}
                        adornment="percent"
                        isDisabled
                      />
                      {accidentCategoryCode[moduleItem.code].map((item) => (
                        <StyledTableRow>
                          <PolicyTableCheckboxCell
                            name={`${item.name}Checked`}
                            onChange={onCheckedFieldChange}
                            isDisabled={!isProductIncludedChecked}
                          />
                          <StyledTableCell align="left" component="th" scope="row">
                            {item.displayName}
                          </StyledTableCell>

                          {moduleItem.tableType === POLICY_MODULE_TYPE.ORIGINAL
                            && (
                              <>
                                <PolicyTableInputCell
                                  name={item.name}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                  isDisabled={!isProductIncludedChecked}
                                />
                                <PolicyTableInputCell
                                  name={`${item.name}2`}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                  isBenefit2
                                  isDisabled={!isProductIncludedChecked}
                                />
                              </>
                            )}

                          {moduleItem.tableType === POLICY_MODULE_TYPE.PERIOD
                            && (
                              <>
                                <PolicyTableInputCell
                                  name={item.name}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                  isDisabled={!isProductIncludedChecked}
                                />
                                <PolicyTableInputCell
                                  name={`${item.name}2`}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                  isBenefit2
                                  isDisabled={!isProductIncludedChecked}
                                />
                              </>
                            )}

                          {moduleItem.tableType === POLICY_MODULE_TYPE.PERIOD
                            && (item.type === POLICY_ITEM_TYPE.PERIOD
                              ? (
                                <PolicyTableInputCell
                                  name={item.periodName}
                                  onChange={updateAllPayout}
                                  max={100}
                                  isDisabled={!isProductIncludedChecked}
                                />
                              )
                              : <StyledTableCell />)}

                          {moduleItem.tableType === POLICY_MODULE_TYPE.FRACT
                            && (
                              <>
                                <PolicyTableInputCell
                                  name={`${item.name}Closed`}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                  isDisabled={!isProductIncludedChecked}
                                />
                                <PolicyTableInputCell
                                  name={`${item.name}Open`}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                  isDisabled={!isProductIncludedChecked}
                                />
                                <PolicyTableInputCell
                                  name={`${item.name}Closed2`}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                  isBenefit2
                                  isDisabled={!isProductIncludedChecked}
                                />
                                <PolicyTableInputCell
                                  name={`${item.name}Open2`}
                                  onChange={updateAllPayout}
                                  adornment="dollar"
                                  isBenefit2
                                  isDisabled={!isProductIncludedChecked}
                                />
                              </>
                            )}

                          <StyledTableCell align="left">
                            {item.code in claim
                              ? intFormatter.format(
                                Math.round(claim[item.code].totalCount * claimWeight.AC),
                              ) : null}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {item.name in payout ? formatter.format(payout[item.name]) : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )

      ))}

    </Grid>
  );
}
