export const COOKIE_JWT = 'JWT';

export const MAIN_HOST = process.env.REACT_APP_BACKEND_HOST;

export default COOKIE_JWT;

export const FORMAT_DATE = 'MM/DD/YYYY';

export const CARRIER_CODE = 'carrierCode';

export const BST_CARRIER = 'BST';

export const AFLAC_SMAL = process.env.REACT_APP_AFLAC_SMAL;

export const AFLAC_CARRIER_CODE = 'AFLAC';

export const ALLIANT_BROKER_CODE = 'ALLIANT';

export const CLIENT_PORTAL_URL = process.env.REACT_APP_CLIENT_PORTAL;

export const CLIENT_PORTAL_URL_LOGOUT = process.env.REACT_APP_CLIENT_PORTAL_LOGOUT;

export const APPDYNAMICS_KEY = process.env.REACT_APP_APPDYNAMICS_KEY;
