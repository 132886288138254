import React from 'react';
import Warning from '@mui/icons-material/Warning';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
} from '@mui/material';

export default function CancelScenarioDialog({
  isOpen, onClose,
  handleStepClick, index, subindex, leavingPage, sourceScreen, handleCoverageStepClick,
  logout, navigateTo, path, isExit,
}) {
  const setOpenAlertDialog = () => {
    if (index && subindex) {
      handleCoverageStepClick(index, subindex, true);
    } else if (index) {
      handleStepClick(index, true);
    } else if (sourceScreen) {
      leavingPage(sourceScreen);
    } else if (path) {
      navigateTo(path, true);
    } else if (logout) {
      logout(true);
    }
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ fontSize: '1.25rem', color: '#002E74' }}>
        <Warning sx={{ marginBottom: '-5px' }} />
        &nbsp;
        Are you sure you want to&nbsp;
        {isExit ? 'exit' : 'cancel'}
        ?
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ fontSize: '1rem', marginBottom: '1rem' }}>
        <Typography>
          You have unsaved changes. Are you sure you want to leave this page?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          REMAIN ON PAGE
        </Button>
        <Button onClick={setOpenAlertDialog} color="primary" variant="contained">
          YES,&nbsp;
          {isExit ? 'EXIT' : 'CANCEL'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
