import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

// eslint-disable-next-line import/prefer-default-export
export const getAlertBanners = createAsyncThunk(
  'getAlertBanners',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/banner/all`);
    return response.data;
  },
);
