import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'app/store';
import MaterialTable from '@material-table/core';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';
import { Delete, Edit } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import {
  IconButton, Link, Chip, Box, Typography, Grid, Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import { confirmDialog } from 'common/ComfirmationDialog';
import {
  pageSizeOptionList, defaultPageSize, defaultOrder, defaultFilters,
} from 'common/Constants';
import { validatePositiveInteger, validateMinimumCharacter } from 'common/validations';
import dayjs from 'dayjs';
import { MAIN_HOST } from 'common/AppConstant';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { deleteScenario } from './state/service';
import { resetScenario, updateScenarioSection } from './state/reducer';
import FilterableHeader from './common/filterableHeader';

export default function Scenarios() {
  const tableRef = React.useRef();
  const navigate = useNavigate();
  const shouldRefresh = useSelector((state) => state.scenario.searchRefreshFlag);
  const [filters, setFilters] = useState(defaultFilters);
  const [headcountError, setHeadcountError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emplyerNameError, setEmployerNameError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [debouncedFilters, setDebouncedFilters] = useState({});
  const navigateToDetail = (id = null) => {
    store.dispatch(updateScenarioSection(0));
    let link = RoutePath.app.scenario.subpage.navigate.subpath;

    if (id && id > 0) {
      link += `?id=${id}`;
    } else {
      store.dispatch(resetScenario());
    }
    navigate(link);
  };

  React.useEffect(() => {
    store.dispatch(resetScenario());
  }, []);

  useEffect(() => {
    if (headcountError || nameError || emplyerNameError || userNameError) {
      return undefined;
    }
    const debounced = debounce(() => {
      setDebouncedFilters(filters);
    }, 500);
    debounced();
    return () => debounced.cancel();
  }, [filters]);

  const handleFilterChange = (column, value) => {
    let updatedValue = value;
    if (column === 'numOfMembers') {
      const errorMessage = validatePositiveInteger(value.value, true);
      setHeadcountError(errorMessage === true ? '' : errorMessage);
    } else if (column === 'region') {
      const setToAll = updatedValue.at(-1) === 'All' || !updatedValue.length;
      updatedValue = setToAll ? ['All'] : updatedValue.filter((region) => region !== 'All');
    } else if (column === 'name') {
      const errorMessage = validateMinimumCharacter(value);
      setNameError(errorMessage === true ? '' : errorMessage);
    } else if (column === 'employerName') {
      const errorMessage = validateMinimumCharacter(value);
      setEmployerNameError(errorMessage === true ? '' : errorMessage);
    } else if (column === 'userName') {
      const errorMessage = validateMinimumCharacter(value);
      setUserNameError(errorMessage === true ? '' : errorMessage);
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: updatedValue,
    }));
  };

  const handleDateChange = (newDate) => {
    const parsedDate = newDate ? dayjs(newDate) : null;
    const formattedDate = (parsedDate && parsedDate.isValid()) ? parsedDate.toISOString() : '';
    setFilters((prevFilters) => ({
      ...prevFilters,
      createdAt: {
        ...filters.createdAt,
        value: formattedDate,
      },
    }));
  };

  const columns = [
    {
      title: (<FilterableHeader
        columnLabel="Name"
        filterValue={filters.name}
        onFilterChange={(e) => handleFilterChange('name', e.target.value)}
        error={nameError}
        isName
      />),
      field: 'name',
      width: '29%',
      render: (rowData) => (
        <IconButton
          disableFocusRipple
          disableRipple
          onClick={() => {
            navigateToDetail(rowData.id);
          }}
          style={{
            justifyContent: 'start',
            overflow: 'hidden',
            textOverflow: 'none',
            borderRadius: 0,
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            component="button"
            underline="none"
            style={{
              textWrap: 'wrap',
              textAlign: 'left',
              wordBreak: 'break-all',
            }}
          >
            {rowData.name}
          </Link>
          &nbsp;
          {rowData.privateScenario
            && (
              <Chip label="Private" />
            )}
        </IconButton>
      ),
    },
    {
      title: (<FilterableHeader
        columnLabel="Employer Name"
        filterValue={filters.employerName}
        error={emplyerNameError}
        onFilterChange={(e) => handleFilterChange('employerName', e.target.value)}
      />),
      field: 'employerName',
      width: '12%',
    },
    {
      title: (<FilterableHeader
        columnLabel="Region"
        filterValue={filters.region}
        onFilterChange={(e) => handleFilterChange('region', e.target.value)}
        isDropdown
      />),
      field: 'region',
    },
    {
      title: (<FilterableHeader
        columnLabel="Headcount"
        onFilterChange={(value) => handleFilterChange('numOfMembers', value)}
        filters={filters}
        error={headcountError}
        isHeadcount
      />),
      field: 'numOfMembers',
    },
    {
      title: (<FilterableHeader
        columnLabel="Census"
        filterable={false}
      />),
      field: 'censusFile',
      sorting: false,
      render: (rowData) => (
        <a href={rowData.censusFileUrl}>
          <ArticleIcon />
          {' '}
        </a>
      ),
    },
    {
      title: (<FilterableHeader
        columnLabel="User"
        filterValue={filters.userName}
        error={userNameError}
        onFilterChange={(e) => handleFilterChange('userName', e.target.value)}
      />),
      field: 'userName',
    },
    {
      title: (<FilterableHeader
        columnLabel="Created"
        onFilterChange={(value) => handleFilterChange('createdAt', value)}
        filters={filters}
        isCreated
        onDateChange={handleDateChange}
      />),
      field: 'createdAt',
      width: '15%',
      render: (rowData) => (rowData.createdAt ? (
        <span>
          {' '}
          {new Date(rowData.createdAt).toLocaleString()}
          {' '}
        </span>
      ) : <span />),
    },
    {
      title: (
        <Grid container direction="column" alignItems="flex-start" spacing={12} style={{ paddingLeft: '8px' }}>
          <Grid item>
            {' '}
          </Grid>
          <Grid item>
            <Typography variant="h7">Actions</Typography>
          </Grid>
        </Grid>),
      field: 'actions',
      sorting: false,
      render: (rowData) => (
        <Grid container>
          <Grid item>
            <Tooltip title="Edit">
              <IconButton onClick={() => navigateToDetail(rowData.id)}>
                <Edit sx={{ color: 'black' }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Delete">
              <IconButton>
                <Delete
                  sx={{ color: 'black' }}
                  onClick={() => confirmDialog(
                    () => store.dispatch(deleteScenario(rowData.id)),
                    'Are you sure you wish to remove the whole scenario?',
                  )}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item style={{ flexGrow: 1, width: '70px' }}>
            {' '}
          </Grid>
          <Grid item style={{ flexGrow: 1, width: '70px' }}>
            {' '}
          </Grid>
        </Grid>
      ),
    },
  ];
  /* eslint-disable */
  const refresh = () => {
    tableRef.current && tableRef.current.onQueryChange();
  }

  React.useEffect(() => {
    refresh();
  }, [shouldRefresh, debouncedFilters]);

  const clearFilters = () => {
    setFilters(defaultFilters);
    setHeadcountError('');
    setNameError('');
    setEmployerNameError('');
    setUserNameError('');
    refresh();
  };

  return (
    <>
      <MaterialTable
        title="Scenario list"
        tableRef={tableRef}
        columns={columns}
        components={{
          OverlayLoading: () => (<div></div>)
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No results found',
          },
        }}
        options={{
          actionsColumnIndex: -1,
          search: false,
          pageSizeOptions: pageSizeOptionList,
          pageSize: defaultPageSize,
          emptyRowsWhenPaging: false,
        }}
        data={(query) =>
          new Promise((resolve) => {
            axios.post(`${MAIN_HOST}/scenario/search`, {
              page: query.page,
              size: query.pageSize,
              orderBy: Array.isArray(query.orderByCollection)
                        && query.orderByCollection.length === 1
                        ? columns[query.orderByCollection[0].orderBy].field
                        : 'createdAt',
              orderDirection: Array.isArray(query.orderByCollection)
                                && query.orderByCollection.length === 1
                                && query.orderByCollection[0].orderDirection
                                ? query.orderByCollection[0].orderDirection.toUpperCase()
                                : defaultOrder,
              filters: debouncedFilters
            })
            .then((res) => {
              const data = res.data;
              resolve({
                data: data.content,
                page: data.number,
                size: data.size,
                totalCount: data.totalElements,
              });
            })
            .catch(() => {
              resolve({
                data: [],
                page: 0,
                totalCount: 0,
              });
            });
          })
        }
        actions={[
          {
            icon: () => <Link href="#" variant='body2'>Clear Filters</Link>,
            tooltip: 'Clear',
            onClick: () => clearFilters(),
            isFreeAction: true,
          },
          {
            icon: () => <Button variant="contained" size="small">REFRESH</Button>,
            tooltip: 'Refresh Data',
            onClick: () => refresh(),
            isFreeAction: true,
          },
          {
            icon: () => <Button variant="contained" size="small">+ CREATE SCENARIO</Button>,
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => navigateToDetail(),
          },
        ]}
      />
      <Box component="footer" sx={{ fontSize: '0.8rem', paddingTop: '25px' }}>
          MultiPlan Inc. All Rights Reserved.
      </Box>
    </>
  );
}
