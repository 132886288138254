import React, { useState } from 'react';

import { Alert, Typography, Box } from '@mui/material';

function AlertBanner({ message, type }) {
  const [isVisible, setIsVisibile] = useState(true);

  return isVisible ? (
    <Alert
      onClose={() => setIsVisibile(false)}
      severity={`${type === 'MAINTENANCE' ? 'info' : 'warning'}`}
      variant="filled"
      sx={{
        position: 'absolute', left: 0, right: 0, top: 0, zIndex: 10000,
      }}
    >
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Typography variant="body" fontWeight="bold">
          {type === 'MAINTENANCE' ? 'Scheduled Maintenance' : 'Issue'}
        </Typography>
        <Typography variant="body">
          &nbsp;
          {`- ${message}`}
        </Typography>
      </Box>
    </Alert>
  ) : null;
}

export default AlertBanner;
