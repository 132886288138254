import React, { useRef } from 'react';
import {
  Grid, Typography, Card, Divider, TableContainer, Table, TableHead, TableBody, TableRow, Paper,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ExportImage from 'common/components/exportImage';
import { frequencyOptions, premiumAges, premiumTypes } from 'common/Constants';
import { StyledTableCell, StyledTableRow } from './common/styledTableComponent';

function OutputPremiums() {
  const premiumsRef = useRef();
  const originalScenario = useSelector(
    (state) => state.scenario,
  );
  const CIPremiumsInfo = useSelector(
    (state) => state.scenario.criticalIllnessPremiumsSectionInfo,
  );
  const HIPremiumsInfo = useSelector(
    (state) => state.scenario.HIPremiumsInfo,
  );
  const ACPremiumsInfo = useSelector(
    (state) => state.scenario.ACPremiumsInfo,
  );

  const getPremiumFrequency = () => frequencyOptions
    .find((opt) => opt.code === originalScenario.scenario.premiumFrequency)?.text;

  if (!HIPremiumsInfo.includePremiumHI
    && !CIPremiumsInfo.includePremiumCI
    && !ACPremiumsInfo.includePremiumAC) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Card ref={premiumsRef}>
        <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
          <Typography variant="h5">
            Premiums
            {originalScenario.scenario.premiumFrequency && ` - ${getPremiumFrequency()}`}
          </Typography>
          <ExportImage sectionRef={premiumsRef} sectionName="premiums" />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item container xs={12} spacing={1} sx={{ marginTop: '0.3rem' }}>
          {HIPremiumsInfo.includePremiumHI && (
            <Grid item xs={CIPremiumsInfo.premiumTypeCI === 'SMOKER' ? 3 : 4}>
              <Typography variant="h6" sx={{ marginLeft: '0.5rem', color: '#002D74' }}>
                Hospital Indemnity
              </Typography>
              <TableContainer component={Paper} margin={0} elevation={2} sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                <Table aria-label="main table" data-testid="HI_premiums" sx={{ width: 'auto' }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '50%' }}>
                        {' '}
                        <Typography fontWeight="600">Type</Typography>
                        {' '}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '50%' }}>
                        {' '}
                        <Typography fontWeight="600">Premium Amount</Typography>
                        {' '}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {premiumTypes.map((item) => (
                      <StyledTableRow>
                        <StyledTableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ width: '30%' }}
                        >
                          {item.type}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ width: '30%' }}
                        >
                          $&nbsp;
                          {HIPremiumsInfo[`HI${item.name}Premium`] || 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {ACPremiumsInfo.includePremiumAC && (
            <Grid item xs={CIPremiumsInfo.premiumTypeCI === 'SMOKER' ? 3 : 4}>
              <Typography variant="h6" sx={{ marginLeft: '0.5rem', color: '#002D74' }}>
                Accident
              </Typography>
              <TableContainer component={Paper} margin={0} elevation={2} sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                <Table aria-label="main table" data-testid="HI_premiums" sx={{ width: 'auto' }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '50%' }}>
                        {' '}
                        <Typography fontWeight="600">Type</Typography>
                        {' '}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '50%' }}>
                        {' '}
                        <Typography fontWeight="600">Premium Amount</Typography>
                        {' '}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {premiumTypes.map((item) => (
                      <StyledTableRow>
                        <StyledTableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ width: '30%' }}
                        >
                          {item.type}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ width: '30%' }}
                        >
                          $&nbsp;
                          {ACPremiumsInfo[`AC${item.name}Premium`] || 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {CIPremiumsInfo.includePremiumCI && (
            <Grid item xs={CIPremiumsInfo.premiumTypeCI === 'SMOKER' ? 6 : 4}>
              <Typography variant="h6" sx={{ marginLeft: '0.5rem', color: '#002D74' }}>
                Critical Illness
              </Typography>
              <TableContainer component={Paper} margin={0} elevation={2} sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                <Table aria-label="main table" sx={{ width: 'auto' }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell style={{ width: '10%' }}>
                        {' '}
                        <Typography fontWeight="600">Age</Typography>
                        {' '}
                      </StyledTableCell>
                      {['UNISMOKER', 'SMOKER'].includes(CIPremiumsInfo.premiumTypeCI)
                        && (
                        <StyledTableCell style={{ width: '20%' }}>
                          {' '}
                          <Typography fontWeight="600">
                            Premium Amount
                          </Typography>
                          {CIPremiumsInfo.premiumTypeCI === 'SMOKER' && <Typography fontWeight="600"> - Nonsmoker</Typography>}
                          {' '}
                        </StyledTableCell>
                        )}
                      {CIPremiumsInfo.premiumTypeCI === 'SMOKER'
                        && (
                        <StyledTableCell style={{ width: '20%' }}>
                          {' '}
                          <Typography fontWeight="600">Premium Amount - Smoker</Typography>
                          {' '}
                        </StyledTableCell>
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {premiumAges.map((premiumAge) => (
                      <StyledTableRow>
                        <StyledTableCell
                          align="left"
                          component="th"
                          scope="row"
                        >
                          {premiumAge}
                        </StyledTableCell>
                        {['UNISMOKER', 'SMOKER'].includes(CIPremiumsInfo.premiumTypeCI)
                          && (
                          <StyledTableCell
                            align="left"
                            component="th"
                            scope="row"
                          >
                            $&nbsp;
                            {CIPremiumsInfo[`CI${premiumAge}Premium`] || 0}
                          </StyledTableCell>
                          )}
                        {CIPremiumsInfo.premiumTypeCI === 'SMOKER'
                          && (
                            <StyledTableCell
                              align="left"
                              component="th"
                              scope="row"
                            >
                              $&nbsp;
                              {CIPremiumsInfo[`CI${premiumAge}Premium2`] || 0}
                            </StyledTableCell>
                          )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  );
}

export default OutputPremiums;
