import * as React from 'react';
import AppBar from 'common/components/navigatorComponents';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import {
  Button,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { DirectionsRun, DirectionsWalk, RefreshOutlined } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
  CHECK_GREEN,
  RUNNING_ORANGE,
  PRIMARY_WARNING,
  STATUS_CODE,
} from 'common/Constants';
import store from 'app/store';
import { getById } from 'features/scenario/state/service';

export default function BottomBar(props) {
  const {
    firstButtonDisplayName, secondButtonDisplayName, onClickBackButton, isBackButton,
    onClickFirstButton, onClickSecondButton, firstButtonDirtyFlag, secondButtonDirtyFlag,
    showGoToDemographic, onClickGoToDemographic, goToDemographicDisableFlag, processStatus,
    censusFileFlag, showSaveButton, onClickSaveButton, isSaveButtonDisabled, showFirstButton = true,
  } = props;

  const sideBarOpen = useSelector((state) => state.navigator.sideBarOpen);
  const scenarioId = useSelector((state) => state.scenario.scenarioId);
  const [isRunning, setIsRunning] = React.useState(true);
  const completedFlag = useSelector((state) => state.scenario.completedFlag);

  const refreshNavigate = () => {
    if (scenarioId && scenarioId > 0) {
      store.dispatch(getById(scenarioId));
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIsRunning((prevIsRunning) => !prevIsRunning);
    }, 400);
    return () => clearInterval(interval);
  }, []);

  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }} open={sideBarOpen}>
      <Toolbar>
        {!censusFileFlag && processStatus === STATUS_CODE.PYTHON_INVALID && (
          <Box display="flex" alignContent="center" flexWrap="wrap">
            <ErrorIcon sx={{ color: PRIMARY_WARNING }} fontSize="small" />
            <span style={{ color: PRIMARY_WARNING }}>&nbsp;Invalid Census File</span>
          </Box>
        )}
        {processStatus === STATUS_CODE.RUNNING && (
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Tooltip
              title="Refresh the page to check for results"
              placement="top"
            >
              <IconButton
                onClick={() => refreshNavigate()}
              >
                <RefreshOutlined sx={{ color: RUNNING_ORANGE }} fontSize="small" />
              </IconButton>
            </Tooltip>
            <Typography>
              <span style={{ color: RUNNING_ORANGE }}>&nbsp;Process Running</span>
            </Typography>
            <IconButton disableRipple disableTouchRipple sx={{ color: RUNNING_ORANGE }}>
              {isRunning ? <DirectionsRun /> : <DirectionsWalk />}
            </IconButton>
          </Box>
        )}
        {completedFlag && processStatus === STATUS_CODE.SUCCESS && (
          <Box display="flex" alignContent="center" flexWrap="wrap">
            <CheckCircleIcon sx={{ color: CHECK_GREEN }} fontSize="small" />
            <span style={{ color: CHECK_GREEN }}>&nbsp;Completed</span>
          </Box>
        )}
        {isBackButton ? isBackButton && (
          <Button onClick={onClickBackButton}>
            BACK
          </Button>
        ) : null}
        <Box sx={{ flexGrow: 1 }} />
        {!!showSaveButton && (
          <Button
            variant="contained"
            onClick={onClickSaveButton}
            sx={{ marginRight: '15px' }}
            disabled={isSaveButtonDisabled}
          >
            SAVE
          </Button>
        )}
        {showFirstButton && (
          <Button
            variant="outlined"
            onClick={onClickFirstButton}
            disabled={firstButtonDirtyFlag ?? false}
            sx={{ marginRight: '15px' }}
          >
            {firstButtonDisplayName}
          </Button>
        )}
        {showGoToDemographic === true && (
          <Button
            variant="contained"
            sx={{ marginRight: '15px' }}
            disabled={goToDemographicDisableFlag}
            onClick={onClickGoToDemographic}
          >
            GO TO DEMOGRAPHIC
          </Button>
        )}
        <Button
          variant="contained"
          onClick={onClickSecondButton}
          disabled={secondButtonDirtyFlag ?? false}
        >
          {secondButtonDisplayName}
        </Button>
      </Toolbar>
    </AppBar>
  );
}
