const nonNegativeIntegerRegex = /^\d+$/;
const positiveIntegerRegex = /^[1-9]\d*$/;
const positiveIntAcceptSpacesRegex = /^\s*[1-9]\d*\s*$/;

export const validateWholePercentage = (value) => {
  if (value === '' || value === null) {
    return true;
  }
  const numberValue = Number(value);
  return (nonNegativeIntegerRegex.test(value) && numberValue >= 0 && numberValue <= 100) || 'Must be between 0-100 and a whole number';
};

export const validateNonNegativeInteger = (value) => {
  if (value === '' || value === null) {
    return true;
  }
  return nonNegativeIntegerRegex.test(value) || 'Must be greater than or equal to 0 and a whole number';
};

export const validatePositiveInteger = (value, acceptSpaces = false) => {
  const HEADCOUNT_ERROR = 'Headcount must be greater than 0 and a whole number';
  if (value === '' || value === null) {
    return true;
  }
  if (acceptSpaces) {
    return positiveIntAcceptSpacesRegex.test(value) || HEADCOUNT_ERROR;
  }
  return positiveIntegerRegex.test(value) || HEADCOUNT_ERROR;
};

export const validateMinimumCharacter = (value) => {
  const allCharactersRegex = /^\s*\*+\s*$/;
  if (value === '' || value === null || allCharactersRegex.test(value)) {
    return true;
  }
  const minimumCharacterRegex = /^.{3,}$/;
  return minimumCharacterRegex.test(value) || 'Type in 3 or more characters';
};
